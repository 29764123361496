import { faBars, faChevronDown, faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useState} from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { logout } from '../../../../../Slice/authSlice';
import { useDispatch, useSelector } from 'react-redux';

const Header = () => {

    const [activatedportal, setActivatedPortal] = useState("cxo");
    const [logoutopen, setLogoutOpen] = useState(false)
    const [ navbaropen, setNavBarOpen ] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const textColor = location.pathname === "/cxo" ? "text-white" : "text-black";
    console.log("location.pathname",location.pathname)
    console.log("textColor",textColor)
    const auth = useSelector((state) => state.auth.user);

    const handleportal = (portal) => {
        setActivatedPortal(portal)
    }

    const handleLogout = () => {
 
      dispatch(logout());
    
      localStorage.clear();
      sessionStorage.clear();
 
      document.cookie.split(";").forEach((cookie) => {
        document.cookie = cookie
          .replace(/^ +/, "")
          .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`);
      });
    
      navigate("/CXO/login");
    };
    
      const handleLogin = () => {
        navigate('/CXO/login');
      };

      const navLinks = [
        { name: "Home", path: "/cxo" },
        { name: "My Dashboard", path: "/cxo/dashboard" },
        { name: "Career Hub", path: "/cxo/career-hub" },
        { name: "DNA & AI/HI", path: "/cxo/dna-ai-hi" },
        { name: "Query", path: "/cxo/ticket" },
      ];



  return (
    <div className=" relative " >
        {/* <div className=" bg-[#D9D9D92B] shadow-sm shadow-black rounded-b-3xl lg:w-[50%] w-[95%] mx-auto h-16 px-10 flex justify-between items-center text-black font-bold text-sm ">
            <button onClick={() => {handleportal("skill")}} className={` transition-all ease-in duration-300 ${activatedportal === "skill" ? "bg-[#87F5D1] rounded-xl py-2 px-7 text-black " : ""} `} >
                <p className='md:text-base text-sm'>Skill Portal</p>
            </button>
            <button onClick={() => {handleportal("coe")} }className={` transition-all ease-in duration-300 ${activatedportal === "coe" ? "bg-[#87F5D1] rounded-xl py-2 px-7 text-black " : ""} `} >
                <p className='md:text-base text-sm'>COE Portal</p>
            </button>
            <button onClick={() => {handleportal("cxo")} }className={` transition-all ease-in duration-300 ${activatedportal === "cxo" ? "bg-[#87F5D1] rounded-xl py-2 px-7 text-black " : ""} `} >
                <p className='md:text-base text-sm'>CXO Portal</p>
            </button>

        </div> */}
        <div className=' absolute md:top-7 top-5 right-10 '>
        {auth ? (
          <div className="flex items-center space-x-4">
          <div onClick={() => setLogoutOpen(!logoutopen)} className=' cursor-pointer flex items-center gap-2'>
          <div className='relative'>
          <img src="/assets/image 90 copy.png" className='w-10 z-30 ' />
          </div>   
          <div className=' flex items-center gap-3 '>     
          <p className={`${textColor} truncate w-24 `}>{auth.email}</p>
          <FontAwesomeIcon icon={faChevronDown} className='text-white' />
          </div>
          </div>
          {logoutopen && (
            <div className=' absolute top-12 bg-white rounded-lg px-5 py-3 flex justify-center items-center '>
            <button
            onClick={handleLogout}
            className={`px-4 py-2 text-black flex items-center gap-2 font-semibold transition duration-200`}
          >
            <FontAwesomeIcon icon={ faDoorOpen } className='text-black' />Logout
          </button>
          </div>
          )}
          
          </div>
      ) : (
        <button   onClick={handleLogin} className="flex pt-3 pb-1 px-3 rounded-full group border border-sky-700 hover:shadow-[0_0_0px_#fff,inset_0_0_0px_#fff,0_0_10px_#54f9e3,0_0_10px_#54f9e3] text-white">
        <button   className="p-2 rounded-full translate-y-0.5 group-hover:translate-x-[112px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5"></button>{" "}
        <p className={`mt-0.5 ${textColor} -translate-y-1 group-hover:text-lg group-hover:-translate-x-6 transform-transition duration-500 ease-in-out`}>
          Login
        </p>{" "}
        <button className="ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]"></button>
      </button> 

      )}
         </div>
        <div className="w-full py-7 lg:px-10 flex md:justify-start justify-between items-center px-3 gap-10">
        <div className=" lg:w-[15%] " >
            <img src="../assets/logo/Logod5art.png" className="w-32" />
        </div>

        <div className=' lg:w-[50%] w-[70%] md:block hidden '>
        <nav className={`${textColor} font-bold flex justify-between text-sm`}>
            {navLinks.map(({ name, path }) => (
              <NavLink
                key={name}
                to={path}
                className={({ isActive }) =>
                `hover:text-gray-300 ${isActive ? "text-yellow-400" : ""}`
                }
              >
                {name}
              </NavLink>
            ))}
        </nav>
        </div>


        <div className=' md:hidden block '>
            <button onClick={() => setNavBarOpen(!navbaropen)}>
           <FontAwesomeIcon icon={faBars} className='text-white' />
            </button>

           {navbaropen && (
             <div className=' md:hidden block relative '>
             <nav className={`w-36 bg-white rounded-lg ${textColor}font-bold text-sm absolute top-5 -left-32 py-5 px-3 flex flex-col gap-3 items-center`}>
              {navLinks.map(({ name, path }) => (
              <NavLink
                key={name}
                to={path}
                className={({ isActive }) =>
              `hover:text-gray-600 ${isActive ? "text-blue-500" : ""}`
                }
                onClick={() => setNavBarOpen(false)} 
              >
                {name}
              </NavLink>
             ))}
             </nav>
             </div>
           )}
        </div>



        </div>


    </div>
  )
}

export default Header