import React, { useEffect, useState } from 'react';
import Header from '../Layout/Header';
import EditModal from '../Dashboard/EditModal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../../../../../Slice/profileSlice';
// import Header from '../../Layout/Header';

const Section1 = () => {
   const dispatch = useDispatch();
   const { user, loading } = useSelector((state) => state.userProfile);
   console.log(" user, loading", user, loading)
   const userEmail = useSelector((state) => state.auth.user);
   const [isModalOpen, setIsModalOpen] = useState(false);
   useEffect(() => {
      if (userEmail?.email) {
        dispatch(fetchUser(userEmail?.email));
      }
    }, [dispatch, userEmail]);
  
    useEffect(() => {
      if (!loading && (user?.message === "User not found" || !user)) {
        setIsModalOpen(true); 
      }
    }, [loading, user]);
    
  
  return (
    <div className="w-full h-full bg-cover bg-no-repeat " style={{ backgroundImage: "url(../assets/CXO/Landing/bg.svg)" }}>
        <div className=" bg-[#13111171] h-full w-full " >
           <Header/>
        <div className="lg:px-20 px-5 w-full max-w-2xl md:mt-32 mt-12 text-white pb-20">
           <p className=" md:text-5xl text-3xl font-bold "><span style={{ backgroundClip:"text", color: "text", webkitBackgroundClip: "" }}>Dive</span> Into The Depths</p>
           <p className=" md:text-5xl text-3xl font-bold ">Of Virtual Reality</p>
           <p className="md:mt-5 mt-7 font-light md:text-base text-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
              sed do eiusmod tempor incididunt ut labore et dolore 
              nisl tincidunt eget. Lectus mauris eros in vitae .</p>

           <div className="md:mt-10 mt-12 flex items-center gap-7">
            <button className="md:text-base text-sm bg-gradient-to-r from-[#0B7CACA6] to-[#51CBFFA6] rounded-full py-2 px-10 border border-[#51CBFFCC] font-semibold ">Register</button>

            <button className="md:text-base text-sm rounded-full py-2 px-5 border border-[#51CBFFCC] font-semibold ">Explore Now {" "} {" "} <span><i class="fa-solid fa-arrow-right" style={{ color: "#51CBFF" }} ></i></span> </button>
           </div>
        </div>
        </div>
        {isModalOpen && (
        <EditModal
          user={user}
          userEmail={userEmail}
          disableCancel={user?.message === "User not found"}
          onClose={() => setIsModalOpen(false)}
        />
      )}
        </div>
  )
}

export default Section1