import React from 'react'

const Section6 = () => {
  return (
    <div className='bg-white h-full relative font-archivo'>
        <div className=' bg-white h-full mt-12 pb-36'>
        <h1 className='text-center text-3xl font-semibold text-[#222222]'>Key   Offerings</h1>
        </div>
        <div className=' bg-[#DDDDDD87] h-full pb-10 '>
        <div className=' lg:w-[75%] w-[90%] mx-auto flex md:flex-row flex-col justify-center items-center gap-5'>
              <div className='md:w-1/2 w-full bg-[#E5E7EC] shadow-md  rounded-3xl border-8 border-white -translate-y-24 '>
                <img src='../assets/CXO/Landing/image3.svg' className='h-40 mx-auto'/>
                <div className=' bg-[#DFDFDF] rounded-b-3xl py-3 lg:h-24 '>
                    <p className=' text-xl font-bold text-center '>DNA </p>
                    <p className='text-sm text-center lg:w-[80%] w-[95%] mx-auto mt-1'>Unleash your inner power to <span className='font-bold' style={{ backgroundClip: "text", color:"transparent", WebkitBackgroundClip:"text", backgroundImage: "linear-gradient( to right, #00768B, #F29D38 )" }} >Fuel Your Dreams</span> and conquer every challenge in your path.</p>   
                </div>

              </div>
              <div className='md:w-1/2 w-full bg-[#E5E7EC] shadow-md  rounded-3xl border-8 border-white -translate-y-24 '>
                <img src='../assets/CXO/Landing/image4.svg' className='h-40 mx-auto'/>
                <div className=' bg-[#DFDFDF] rounded-b-3xl py-3 lg:h-24 '>
                    <p className=' text-xl font-bold text-center '>Hi - Ai Validation</p>
                    <p className='text-sm text-center w-[80%] mx-auto mt-1'>Next-gen validation</p>
                </div>
                
              </div>
        </div>
        <div className=''>
             <div className='flex justify-center items-center gap-10'>
                <p className='text-[#888888] text-sm'>UX Research</p>
                <p className='text-[#888888] text-sm'>Wireframe</p>
                <p className='text-[#888888] text-sm'>Visual Design</p>
             </div>
             <div className='mt-7'>
                <p className='text-3xl text-center font-bold text-[#222222]'>Coca - Tech Startup Landing Page</p>
                <p className='text-[#666666] md:w-[50%] w-[70%] mx-auto text-center leading-7 mt-7 '>We start by getting to know our clients, their business goals, and their target audience. This involves conducting research, analyzing data, and discussing ideas with our clients to gain a deep understanding of their needs.</p>
             </div>
        </div>
        </div>
    </div>
  )
}

export default Section6