///TEST

// export const SERVER_URL = "http://localhost:4000";
// export const SERVER_URL1 = "http://localhost:3002";
 export const RAZORPAY_KEY_ID ="rzp_test_YrzYxp9mOwo6N9";
 export const RAZORPAY_KEY_SECRET="QBzSMqEzJeBD73N70FtkNxSt";
//export const STRIPE_PUBLIC_KEY="pk_test_51QdyDuKfpPPID4js5OhcIKWji6M8fzROElQuu47rTShBp3W0p9GOOAfh14Y4MtYhqSJnr1urItCQfqWaSK7wItPG00JYRXxCca";


///LIVE

export const STRIPE_PUBLIC_KEY="pk_live_51QdyDuKfpPPID4jsZAe92YgHJR2QxWKxofZFxYIjerjI3bOPWLLipbv0E8OPKLssdS8kaGYm0i4gHuQ32ShVvRPS00A6Lqjlmr";
//export const RAZORPAY_KEY_ID ="rzp_live_W0JUBprFlbC0MA"
//export const RAZORPAY_KEY_SECRET="oukDWSHIJFFxjS1efQTomniB"
export const SERVER_URL = "https://edu.d5art.com";
export const SERVER_URL1 = "https://ex.d5art.com";




