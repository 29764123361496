import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateUser } from "../../../../../Slice/profileSlice";
import toast from "react-hot-toast";

const skillOptions = ["React", "Node.js", "Tailwind CSS", "HTML", "CSS", "JavaScript"];

const EditModal = ({ user, userEmail,disableCancel, onClose }) => {
  const dispatch = useDispatch();
  
  const parsedSkills = user.skills ? JSON.parse(user.skills) : {};
  const [formData, setFormData] = useState({
    role: user.role,
    company: user.company,
    description: user.description,
    skills: { ...parsedSkills }, 
  });

  const handleSkillChange = (skill, value) => {
    if (value < 0) {
      toast.error("Skill percentage must be greater than 0");
      return;
    }
    setFormData({
      ...formData,
      skills: { ...formData.skills, [skill]: Number(value) },
    });
  };

  const handleAddSkill = (e) => {
    const newSkill = e.target.value;
    if (newSkill && !formData.skills[newSkill]) {
      setFormData({
        ...formData,
        skills: { ...formData.skills, [newSkill]: 50 },
      });
    }
  };

  const handleRemoveSkill = (skill) => {
    const updatedSkills = { ...formData.skills };
    delete updatedSkills[skill];
    setFormData({
      ...formData,
      skills: updatedSkills,
    });
  };

  const handleSubmit = () => {
    dispatch(updateUser({ ...user, ...formData, email: userEmail.email }));
    onClose();
  };

  return (
    <div className="fixed inset-0 z-50 bg-white bg-opacity-50 backdrop-blur-xl flex justify-center items-center">
      <div className="bg-[#F2F1F1BA] shadow-lg shadow-black/25  p-5 rounded-lg w-1/3">
        <h2 className="text-xl font-bold mb-4 font-monda ">Edit Profile</h2>

         <div className=" bg-white rounded-lg p-5 ">
        {/* Role */}
        <label className="block text-sm font-semibold">Role</label>
        <input
          type="text"
          className="w-full px-3 py-1 mt-2 border-b rounded mb-3 bg-transparent focus:outline-none   "
          value={formData.role}
          onChange={(e) => setFormData({ ...formData, role: e.target.value })}
          placeholder="Enter your role here"
        />

        {/* Company */}
        <label className="block text-sm font-semibold">Company</label>
        <input
          type="text"
          className="w-full px-3 py-1 mt-2 border-b rounded mb-3 bg-transparent focus:outline-none   "
          value={formData.company}
          onChange={(e) => setFormData({ ...formData, company: e.target.value })}
          placeholder="Enter here"
        />

        {/* Description */}
        <label className="block text-sm font-semibold">Description</label>
        <textarea
          className="w-full px-3 py-1 mt-2 border rounded mb-3 bg-transparent focus:outline-none   "
          value={formData.description}
          onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          placeholder="Enter here"
        />

        {/* Skills */}
        <label className="block text-sm font-semibold">Skills</label>
        {Object.entries(formData.skills).map(([skill, percentage]) => (
          <div key={skill} className="mb-2">
            <div className="flex justify-between items-center">
              <p>{skill}</p>
              <div className="flex items-center gap-2">
                <input
                  type="number"
                  min="0"
                  max="100"
                  value={percentage}
                  className="w-16 p-1 border rounded"
                  onChange={(e) => handleSkillChange(skill, e.target.value)}
                />
                {/* Remove Skill Icon */}
                <button
                  onClick={() => handleRemoveSkill(skill)}
                  className="text-red-500 hover:text-red-700"
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>
            <div className="bg-gray-300 h-2 rounded">
              <div
                className="bg-blue-500 h-2 rounded"
                style={{ width: `${percentage}%` }}
              ></div>
            </div>
          </div>
        ))}

        {/* Add Skill Dropdown */}
        <select onChange={handleAddSkill} className="w-full p-2 focus:outline-none rounded mt-2">
          <option value="">Add Skill</option>
          {skillOptions
            .filter((s) => !Object.keys(formData.skills).includes(s))
            .map((skill) => (
              <option key={skill} value={skill}>
                {skill}
              </option>
            ))}
        </select>
        </div>
        {/* Buttons */}
        <div className="flex justify-end gap-3 mt-4">
          <button className="px-8 py-1.5 bg-gray-400 text-[#424242] rounded-full text-sm font-semibold" 
           disabled={disableCancel} 
          onClick={onClose}>
            Cancel
          </button>
          <button className="px-8 py-1.5 bg-gradient-to-r from-[#0E2DA7] to-[#51CBFF] text-white rounded-full text-sm font-semibold" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditModal;
