import React, { Suspense, lazy } from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './Components/Pages/ScrollToTop';
import Loader from './Components/Loaders/Loader';
import Home from './Components/Pages/CXO/Pages/Landing/Home';
import Dashboard from './Components/Pages/CXO/Pages/Dashboard/Dashboard';
import PasskeyAuth from './PasskeyAuth';


// Lazy load components
const Landing = lazy(() => import('./Components/Pages/Landing'));
const Faq = lazy(() => import('./Components/Pages/Faq'));
const NotFound = lazy(() => import('./Components/Pages/NotFound'));
const DnaHome = lazy(() => import('./Components/Pages/DNA/DnaHome'));
const DnaTracking = lazy(() => import('./Components/Pages/DNA/DnaTracking'));
const CeoHome = lazy(() => import('./Components/Pages/Coe/Pages/Home'));
const CeoDetails = lazy(() => import('./Components/Pages/Coe/Pages/Coe'));
const CeoCareerHub = lazy(() => import('./Components/Pages/Coe/Pages/CareerHub'));
const CeoMyDashboard = lazy(() => import('./Components/Pages/Coe/Pages/MyDashboard'));
const CeoDnaAi = lazy(() => import('./Components/Pages/Coe/Pages/DnaAi'));
const Bingo = lazy(() => import('./Components/Pages/Game/Bingo'));
const BingoAdmin = lazy(() => import('./Components/Pages/Game/BingoAdmin'));
const Bingogame = lazy(() => import('./Components/Pages/Game/Game'));
const ScheduleMeeting = lazy(() => import('./Components/Pages/Coe/ADMIN/ScheduleMeeting'));
const CreateMeeting = lazy(() => import('./Components/Pages/Coe/ADMIN/Create_meeitng'));
const AddProjects = lazy(() => import('./Components/Pages/Coe/ADMIN/AddProjects'));
const EditProject = lazy(() => import('./Components/Pages/Coe/ADMIN/EditProject'));
const EventHome = lazy(() => import('./Components/Pages/JordanEvent/Pages/Home'));
const CheckEventHome = lazy(() => import('./Components/Pages/JordanEvent/Pages/CheckJordanHome'));
const AddTeamMembers = lazy(() => import('./Components/Pages/JordanEvent/Pages/AddTeamMembers'));
const Instruction = lazy(() => import('./Components/Pages/JordanEvent/Pages/Instruction'));
const TeamMembers = lazy(() => import('./Components/Pages/JordanEvent/Pages/TeamMembers'));
const ProtectedRoute = lazy(() => import('./Components/Pages/ProtectedRoute'));
const Assessment = lazy(() => import('./Components/Pages/JordanEvent/Pages/Assessment'));
const AssessmentResult = lazy(() => import('./Components/Pages/JordanEvent/Pages/AssessmentResult/Result'));
const AdminJordan = lazy(() => import('./Components/Pages/JordanEvent/JordanAdmin/Admin'));
const AdminTeamMembers = lazy(() => import('./Components/Pages/JordanEvent/JordanAdmin/Jordanusers'));
const AdminTeams = lazy(() => import('./Components/Pages/JordanEvent/JordanAdmin/JordanTeams'));
const SyncEmailPage = lazy(() => import('./Components/Pages/SyncEmail'));
const Courses = lazy(() => import('./Components/Pages/AICourses/Courses'));
const CourseDetails = lazy(() => import('./Components/Pages/AICourses/CourseDetails/CourseDetails'));
const CoursePreview = lazy(() => import('./Components/Pages/AICourses/CoursePreview/CoursePreview'));
const Terms_Condition = lazy(() => import('./Components/T&C/Terms_Condition'));
const Privacy_Policy = lazy(() => import('./Components/T&C/Privacy_Policy'));
const Refund_Policy = lazy(() => import('./Components/T&C/Refund_Policy'));



///stripe

const TestDemo = lazy(() => import('./Components/Pages/TestDemo'));
const Stripe = lazy(() => import('./Components/Pages/Stripe'));
const StripeSuccess = lazy(() => import('./Components/Pages/StripeSuccess'));
const StripeFailure = lazy(() => import('./Components/Pages/StripeCancel'));


// CXO
const Login = lazy(() => import('./Components/Pages/CXO/Login/Login'));
const Careerhub = lazy(() => import('./Components/Pages/CXO/Pages/Careerhub/Careerhub'));
const Dna = lazy(() => import('./Components/Pages/CXO/Pages/DNA/Dna'));
const Ticket = lazy(() => import('./Components/Pages/CXO/Pages/Ticket'));


//CXO ADMIN PANEL
const Userdata = lazy(() => import('./Components/Pages/CXO/Adminpanel/Pages/Userdata'));
const Group = lazy(() => import('./Components/Pages/CXO/Adminpanel/Pages/Group'));
const Consultation = lazy(() => import('./Components/Pages/CXO/Adminpanel/Pages/Consultation'));
const Revenue = lazy(() => import('./Components/Pages/CXO/Adminpanel/Pages/Revenue'));








function Routers() {
  const subdomain = window.location.host.split('-')[0];
  const isGame = subdomain === 'game';
  
  console.log("isGame", isGame)

  return (
    <div>
      <ScrollToTop />
      <Suspense fallback={<div><Loader/></div>}>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/Faq" element={<Faq />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/passkey" element={<PasskeyAuth />} />

          <Route path="/sync-email" element={<SyncEmailPage />} />
          <Route path="/ai-courses" element={<Courses />} />
          <Route path="/course-details/:id" element={<CourseDetails />} />
          <Route path="/course-preview" element={<CoursePreview />} />
          <Route path="/terms-and-conditions" element={<Terms_Condition />} />
          <Route path="/privacy-policy" element={<Privacy_Policy />} />
          <Route path="/refund-policy" element={<Refund_Policy />} />
          <Route path="/dna-home" element={<DnaHome />} />
          <Route path="/dna-track" element={<DnaTracking />} />
          <Route path="/coe/home" element={<CeoHome />} />
          <Route path="/coe/details" element={<CeoDetails />} />
          <Route path="/coe/mydashboard" element={<CeoMyDashboard />} />
          <Route path="/coe/careerhub" element={<CeoCareerHub />} />
          <Route path="/coe/dna_ai" element={<CeoDnaAi />} />
          <Route path="/coe/admin/schedul-meeting" element={<ScheduleMeeting />} />
          <Route path="/coe/admin/create-meeting" element={<CreateMeeting />} />
          <Route path="/coe/admin/add-projects" element={<AddProjects />} />
          <Route path="/coe/admin/edit-projects" element={<EditProject />} />
          <Route path="/test" element={<TestDemo />} />
          <Route path="/stripe" element={<Stripe />} />
          <Route path="/stripe-success" element={<StripeSuccess />} />
          <Route path="/stripe-cancel" element={<StripeFailure />} />


          <Route path="/admin/jordan" element={<AdminJordan />} />
          <Route path="/admin/jordan-users" element={<AdminTeamMembers />} />
          <Route path="/admin/jordan-teams" element={<AdminTeams />} />

          {/* <Route path="/jordan-event/" element={<ProtectedRoute element={<EventHome />} />} />
          <Route path="/jordan-event/add-teammembers" element={<ProtectedRoute element={<AddTeamMembers />} />} />
          <Route path="/jordan-event/test-check" element={<ProtectedRoute element={<CheckEventHome />} />} />
          <Route path="/jordan-event/instruction" element={<ProtectedRoute element={<Instruction />} />} />
          <Route path="/event/start-assessment" element={<ProtectedRoute element={<Assessment />} />} />
          <Route path="/jordan-event/assessment-result" element={<ProtectedRoute element={<AssessmentResult />} />} />
          <Route path="/jordan-event/team-members" element={<ProtectedRoute element={<TeamMembers />} />} /> */}


          <Route path="/jordan-event/" element={<ProtectedRoute role="user"><EventHome /></ProtectedRoute>} />
          <Route path="/jordan-event/add-teammembers" element={<ProtectedRoute role="user"><AddTeamMembers /></ProtectedRoute>} />
          <Route path="/jordan-event/test-check" element={<ProtectedRoute role="user"><CheckEventHome /></ProtectedRoute>} />
          <Route path="/jordan-event/instruction" element={<ProtectedRoute role="user"><Instruction /></ProtectedRoute>} />
          <Route path="/event/start-assessment" element={<ProtectedRoute role="user"><Assessment /></ProtectedRoute>} />
          <Route path="/jordan-event/assessment-result" element={<ProtectedRoute role="user"><AssessmentResult /></ProtectedRoute>} />
          <Route path="/jordan-event/team-members" element={<ProtectedRoute role="user"><TeamMembers /></ProtectedRoute>} />

          {/* CXO */}
           <Route path="/CXO/login" element={<Login />} />
          <Route path="/CXO" element={<ProtectedRoute role="cxo"><Home /></ProtectedRoute>} />
          <Route path="/CXO/dashboard" element={<ProtectedRoute role="cxo"><Dashboard /></ProtectedRoute>} />
          <Route path="/CXO/career-hub" element={<ProtectedRoute role="cxo"><Careerhub /></ProtectedRoute>} />
          <Route path="/CXO/dna-ai-hi" element={<ProtectedRoute role="cxo"><Dna /></ProtectedRoute>} />
          <Route path="/CXO/ticket" element={<ProtectedRoute role="cxo"><Ticket /></ProtectedRoute>} />
          <Route path="/CXO/Admin/Userdata" element={<ProtectedRoute role="admin"><Userdata /></ProtectedRoute>} />
          <Route path="/CXO/Admin/Group" element={<ProtectedRoute role="admin"><Group /></ProtectedRoute>} />
          <Route path="/CXO/Admin/Consultation" element={<ProtectedRoute role="admin"><Consultation /></ProtectedRoute>} />
          <Route path="/CXO/Admin/Revenue" element={<ProtectedRoute role="admin"><Revenue /></ProtectedRoute>} />
          
        

        </Routes>
      </Suspense>
    </div>
  );
}

export default Routers;
