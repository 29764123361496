
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; import userReducer from './Slice/userSlice';
import courseProgressReducer  from './Slice/courseProgressSlice';
  // import courseReducer from './Slice/courseSlice';
  import scoresReducer from './Slice/scoresSlice';
  import userScoresReducer from './Slice/rankingsSlice';
  import rankReducer from './Slice/userRankSlice';
  import meetingsReducer from './Slice/meetingsSlice';
  import instituteReducer from './Slice/instituteSlice';
  import projectReducer from './Slice/projectSlice';
  import authReducer from './Slice/authSlice';
  import userProfileReducer from './Slice/profileSlice';
  import adminCxoUsersReducer from './Slice/adminCxoUsersSlice';
  import adminCxoOrganizationReducer from './Slice/adminCxoOrganizationSlice';
  import cxoConsultationReducer from './Slice/cxoConsultationSlice';
  import cxoRevenueReducer from './Slice/cxoRevenueSlice';

  
  

  const rootReducer = combineReducers({
    user: userReducer,
        courseProgress: courseProgressReducer,
        scores: scoresReducer,
        rankings :userScoresReducer,
        rank: rankReducer,
        meetings: meetingsReducer,
        institute: instituteReducer,
        projects: projectReducer,

        auth: authReducer,
        userProfile: userProfileReducer,
        adminCxoUsers:adminCxoUsersReducer,
        adminCxoOrganization:adminCxoOrganizationReducer,
        cxoConsultation:cxoConsultationReducer,
        cxoRevenue:cxoRevenueReducer,

  });

  const persistConfig = {
    key: 'root', 
    storage,
    // whitelist: ['auth'], 
    // blacklist: ['auth'], 
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  export const store = configureStore({
    reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
