import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Section4 = () => {

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <div
            {...props}
            className=" absolute -left-3 top-[43%]  z-10 md:w-10 w-6 md:h-10 h-6 rounded-full border border-black text-black bg-white cursor-pointer duration-500 "
        >
            <div className="flex justify-center items-center w-full h-full">
                <FontAwesomeIcon icon={faChevronLeft} size='sm' />
            </div>
        </div>
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <div
            {...props}
            className=" absolute -right-3 top-[43%]  z-10  md:w-10 w-6 md:h-10 h-6 rounded-full border border-black text-black cursor-pointer  duration-500 "
        >
            <div className="flex justify-center items-center w-full h-full">
                <FontAwesomeIcon icon={faChevronRight} size='sm' />
            </div>
        </div>
    );

    const settings = {
        infinite: true,
        speed: 500,
        dots: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
        responsive: [
            {
                breakpoint: 736,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };


  return (
    <div className=' bg-white h-full relative font-archivo '>
         <div className='bg-white h-full mt-12'>
            <div className='w-[80%] mx-auto  pb-44 '>
         <p className=' font-montserratalt font-bold md:text-7xl text-5xl ' style={{ backgroundClip: "text", WebkitBackgroundClip: "text", color: "transparent", backgroundImage: "linear-gradient( to left, #0E2DA7, #51CBFF )" }} >my<br/> Upskill</p>
         </div>

         <div className=' bg-[#DDDDDD87] h-full pb-10 '>
        <div className=' lg:w-[70%] w-[90%] mx-auto -translate-y-32 z-20 '>
        <Slider  {...settings} className=' w-[85%] mx-auto z-20'>
      <div className='px-5'>
        <div className=' relative rounded-2xl '>
            <img src=' ../assets/CXO/dashboard/image3.svg '/>
            <div className='w-full absolute bottom-0 p-5 bg-[#0F0E0E8F] backdrop-blur rounded-b-2xl '>
                <div className=' w-[90%] mx-auto '>
                <div className='flex md:flex-row flex-col items-center justify-between text-white '>
                    <p className='md:text-base text-sm font-bold uppercase'>AI Trading course</p>
                    <p className='text-xs'>Continue Learning</p>
                </div>
                <div className='mt-5'>
                    <div className=' bg-white rounded-full '>
                        <div className='w-32 bg-gradient-to-r from-[#0E2DA7] to-[#51CBFF] p-1.5 rounded-full '>

                        </div>

                    </div>
                </div>
                </div>
            </div>
        </div>
      </div>
      <div className='px-5'>
      <div className=' relative rounded-2xl'>
            <img src=' ../assets/CXO/dashboard/image4.svg '/>
            <div className='w-full absolute bottom-0 p-5 bg-[#0F0E0E8F] backdrop-blur rounded-b-2xl '>
                <div className=' w-[90%] mx-auto '>
                <div className='flex md:flex-row flex-col items-center justify-center text-white'>
                    <p className='md:text-base text-sm font-bold uppercase'>AI tools for Leaders</p>
                </div>
                <div className='mt-2'>
                    <p className='text-white text-sm font-medium text-center '>Start Learning <span><FontAwesomeIcon icon={faArrowRight} className=' -rotate-45 ' /></span></p>
                    
                </div>
                </div>
            </div>
        </div>
      </div>
    </Slider>
        </div>
        
        </div>
         </div>
    </div>
  )
}

export default Section4