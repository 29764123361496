import React from 'react'

const Section3 = ({skills}) => {
  return (
    <div className=' bg-white h-full '>
        <div className=' w-[80%] mx-auto pt-10 pb-20'>
            <div className=' flex lg:flex-row flex-col justify-between lg:items-center lg:gap-20 gap-7 '>
         <p className=' font-montserratalt font-bold lg:text-8xl text-5xl ' style={{ backgroundClip: "text", WebkitBackgroundClip: "text", color: "transparent", backgroundImage: "linear-gradient( to left, #0E2DA7, #51CBFF )" }} >MY<br/> Network</p>
         <div>
            <p className='text-xs text-[#010101] leading-5'>Networking for CXO-level professionals fosters high-impact connections, enabling the exchange of innovative ideas, strategic partnerships, and industry insights. It cultivates relationships that drive business growth, enhance leadership skills, and provide access to exclusive opportunities, ensuring CXOs stay ahead in a competitive market while expanding their influence within their industries.</p>
            <button className='mt-7 font-archivo bg-[#51CBFF45] rounded-full px-5 py-1 text-[#23515f] font-semibold '>Coming Soon</button>
         </div>
    </div>

    <div className='lg:mt-14 mt-7'>
        <p className=' lg:w-[90%] mx-auto text-xs font-medium text-[#010101] lg:text-center '>Skills are the abilities and expertise acquired through learning and practice, enabling individuals to perform tasks efficiently. They can be technical, creative, or interpersonal, empowering personal and professional growth while driving success in achieving goals and overcoming challenges</p>
        <div className='mt-5 flex lg:flex-row flex-col justify-center items-center gap-14'>
        <div className='lg:w-1/2 bg-[#F1F1F1] shadow-md shadow-black/35 rounded-xl pt-3 px-3 pb-14 mt-10'>
            <div className=' bg-white rounded-xl h-full py-5 inset-shadow-lg inset-shadow-black/35'>
            <div className="w-[85%]  h-56 overflow-hidden overflow-y-auto  mx-auto my-5">
                  {Object.entries(skills).map(([skill, percentage]) => (
                    <div key={skill} className="w-full mt-5">
                      <p className="text-sm">{skill}</p>
                      <div className="mt-1 bg-gradient-to-b from-[#F4F9FF] to-[#BDC2C8] rounded-full w-full">
                        <div
                          className="bg-gradient-to-b from-[#988AFF] to-[#5945ED] h-full rounded-full p-2"
                          style={{
                            width: `${percentage}%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  ))}
                </div>
               <p className='text-2xl font-semibold text-center mt-10 '>My Skills</p>

            </div>
        </div>

        <div className='lg:w-1/2 relative'>
                <p className=' font-montserratalt font-bold md:text-8xl text-5xl rotate-90 absolute md:-right-28 -right-20 md:top-44 top-32 z-0 ' style={{ backgroundClip: "text", WebkitBackgroundClip: "text", color: "transparent", backgroundImage: "linear-gradient( to left, #0E2DA7, #51CBFF )" }} >mY Skills</p>
                <div className='mt-5'>
                    <img src="../assets/Mascots/wizard5.png" className='w-10/12' />
                </div>
            </div>
        </div>
    </div>
    </div>
    </div>
  )
}

export default Section3