import React from 'react'

const Section7 = () => {
  return (
    <div className='py-28 bg-white font-archivo  flex flex-col justify-center items-center'>
        <div className=' w-[90%] mx-auto flex items-center gap-2 '>
            <hr className='w-full border-2 border-[#222222]' />
            <div>
            <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 0.5L20.3215 12.1785L32 16.5L20.3215 20.8215L16 32.5L11.6785 20.8215L0 16.5L11.6785 12.1785L16 0.5Z" fill="#222222"/>
</svg>
            </div>
            <hr className='w-full border-2 border-[#222222]' />
        </div>
        <div className=' w-[80%] mx-auto mt-10 '>
            <div className=' bg-[#E5E7EC] rounded-2xl flex md:flex-row flex-col items-center lg:px-20 sm:px-10 px-5 py-5'>
                <div className=''>
                    <p className=' text-3xl font-semibold '>Reach us</p>
                    <p className='mt-3 w-[90%]'>Finding the right talent for your business can be a daunting task. Let Catalyst Studio's hiring agency take the guesswork out of the process and help you find the perfect fit for your team</p>
                </div>
                <div>
                    <img src='../assets/CXO/Landing/Reachout.svg' className=' md:scale-125 ' />
                </div>
            </div>
        </div>

        <div className=' bg-[#101010] flex items-center gap-5 py-3 px-10 mt-10 rounded-2xl '>
            <p className=' font-bold text-white'>Reach us</p>
            <div className='w-7 h-7 rounded-full bg-[#3AB6FF] border border-white'></div>
        </div>
    </div>
  )
}

export default Section7