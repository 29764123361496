import React from 'react'
import Header from '../Layout/Header'
import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'
import Section4 from './Section4'
import { useSelector } from 'react-redux'
import "../../Style/Style.css"

const Dashboard = () => {
  const { user, loading } = useSelector((state) => state.userProfile);
  const parsedSkills = user.skills ? JSON.parse(user.skills) : {};

  return (
    <div>
        <Header/>
        <Section1/>
        <Section2/>
        <Section3 skills={parsedSkills}/>
        <Section4/>
    </div>
  )
}

export default Dashboard