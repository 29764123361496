import { faArrowRight, faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

const Section4 = () => {

    const [selectedservice, setSelectedService] = useState("1");

    const handleservices = (item) => {
        setSelectedService(item)
    }


  return (
    <div className=' h-screen bg-white font-archivo '>
        <div className='w-[80%] mx-auto flex lg:flex-row flex-col items-end gap-20'>
            <div className='lg:w-[40%]'>
                 <div className=' bg-[#51CBFF] rounded-xl py-3 -skew-x-12 '>
                      <p className=' text-2xl font-bold text-[#222222] text-center skew-x-12 '>Health vs Wealth</p>
                 </div>
                 <div className='mt-5'>
                    <p className='text-[#444444]'>Katalyst Studio offers a range of design services that are tailored to meet the unique needs of each client</p>
                 </div>
                 <div>
                 <div className='mt-5'>
                      <div onClick={() => {handleservices("1")}} className={` cursor-pointer transition-all ease-in duration-200 ${selectedservice === "1" ? "bg-[#101010] text-white" : "text-[#444444] bg-transparent border border-[#DDDDDD]"}  py-4 rounded-full px-5 flex justify-between items-center `}>
                        <div>
                          <p className='font-bold  text-lg'><span className={`pr-5 ${selectedservice === "1" ? "text-[#51CBFF]" : "" }`}>01</span>UI/UX Design</p>
                      </div>
                      <div>
                        <FontAwesomeIcon icon={faArrowRightLong} className='' />
                      </div>
                      </div>
                 </div>
                 <div className='mt-5'>
                      <div onClick={() => {handleservices("2")}} className={` cursor-pointer transition-all ease-in duration-200 ${selectedservice === "2" ? "bg-[#101010] text-white" : "text-[#444444] bg-transparent border border-[#DDDDDD]"}  py-4 rounded-full px-5 flex justify-between items-center `}>
                        <div>
                          <p className='font-bold  text-lg'><span className={`pr-5 ${selectedservice === "2" ? "text-[#51CBFF]" : "" }`}>02</span>Web Development</p>
                      </div>
                      <div>
                        <FontAwesomeIcon icon={faArrowRightLong} className='' />
                      </div>
                      </div>
                 </div>
                 <div className='mt-5'>
                      <div onClick={() => {handleservices("3")}} className={` cursor-pointer transition-all ease-in duration-200 ${selectedservice === "3" ? "bg-[#101010] text-white" : "text-[#444444] bg-transparent border border-[#DDDDDD]"} py-4 rounded-full px-5 flex justify-between items-center `}>
                        <div>
                          <p className='font-bold text-lg'><span className={`pr-5 ${selectedservice === "3" ? "text-[#51CBFF]" : "" }`}>03</span>3D Designs</p>
                      </div>
                      <div>
                        <FontAwesomeIcon icon={faArrowRightLong} className='' />
                      </div>
                      </div>
                 </div>
                 <div className='mt-5'>
                      <div onClick={() => {handleservices("4")}} className={` cursor-pointer transition-all ease-in duration-200 ${selectedservice === "4" ? "bg-[#101010] text-white" : "text-[#444444] bg-transparent border border-[#DDDDDD]"} py-4 rounded-full px-5 flex justify-between items-center `}>
                        <div>
                          <p className='font-bold text-lg'><span className={`pr-5 ${selectedservice === "4" ? "text-[#51CBFF]" : "" }`}>04</span>Motion Graphics</p>
                      </div>
                      <div>
                        <FontAwesomeIcon icon={faArrowRightLong} className='' />
                      </div>
                      </div>
                 </div>
                 </div>

            </div>

            <div className='lg:w-[60%] flex gap-5'>
                <div className=' w-[60%] '>
              <div className=' bg-[#E5E7EC] rounded-2xl pt-5' >
                <img src='../assets/CXO/Landing/section4.svg' />
              </div>
              <div className="flex justify-center items-center px-10 bg-[#E5E7EC] rounded-xl gap-5 mt-4 py-4 ">
                    <p className="font-medium">Explore Now</p>
                    <div className=" rotate-45 w-10 h-10 rounded-full bg-white flex justify-center items-center " ><i class="fa-solid fa-arrow-up"></i></div>
                  </div>
              </div>
              <div className='w-[40%]'>
                 <div className=' bg-[#2B2B2B] rounded-3xl text-white h-1/2 p-5 flex flex-col justify-between'>
                    <p>Ever wondered how design magic happens?</p>
                    <p className='text-xl font-semibold w-1/2 leading-6'>See how we work</p>
                 </div>
                 <div className='mt-3 bg-[#51CBFF] rounded-3xl h-1/2 p-5 flex flex-col justify-between text-[#222222]'>
                    <p>Looking for design experts who can bring your vision to life?</p>
                    <p className='text-xl font-semibold w-1/2 leading-6'>Meet our expert</p>
                 </div>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Section4