import React, { useState } from 'react'

const Section2 = () => {

    const [selectedsection, setSelectedSection] = useState("mydna");

    const handlesection = (data) => {
        setSelectedSection(data)
    }



  return (
    <div className='h-full bg-white'>
       <div className='w-full shadow-md shadow-black/25 mt-14'>
         <div className='flex justify-between items-center flex-wrap px-10'>
            <div onClick={() => {handlesection("mydna")}} className={` ${selectedsection === "mydna" ? " bg-gradient-to-r from-[#00768B] to-[#F29D38]" : " "} cursor-pointer p-1 rounded-full `}>
                <div className='bg-white rounded-full lg:py-3 py-1 lg:md:px-10 px-3 '>
                    <p className={` ${selectedsection === "mydna" ? "text-[#222222]" : ""} font-bold lg:text-lg text-sm text-center `}>My DNA</p> 
                </div>
            </div>
            <div onClick={() => {handlesection("mynetwork")}} className={` ${selectedsection === "mynetwork" ? "bg-gradient-to-r from-[#00768B] to-[#F29D38]" : " "} cursor-pointer  p-1 rounded-full `}>
                <div className='bg-white rounded-full lg:py-3 py-1 lg:px-10 px-3'>
                    <p className={` ${selectedsection === "mynetwork" ? "text-[#222222]" : ""} font-bold lg:text-lg text-sm text-center `}>My Network</p> 
                </div>
            </div>
            <div onClick={() => {handlesection("myskills")}} className={` ${selectedsection === "myskills" ? "bg-gradient-to-r from-[#00768B] to-[#F29D38]" : " "} cursor-pointer  p-1 rounded-full `}>
                <div className='bg-white rounded-full lg:py-3 py-1 lg:px-10 px-3'>
                    <p className={` ${selectedsection === "myskills" ? "text-[#222222]" : ""} font-bold lg:text-lg text-sm text-center `}>My Skills</p> 
                </div>
            </div>
            <div onClick={() => {handlesection("myupskills")}} className={` ${selectedsection === "myupskills" ? "bg-gradient-to-r from-[#00768B] to-[#F29D38]" : " "} cursor-pointer  p-1 rounded-full `}>
                <div className='bg-white rounded-full lg:py-3 py-1 lg:px-10 px-3'>
                    <p className={` ${selectedsection === "myupskills" ? "text-[#222222]" : ""} font-bold lg:text-lg text-sm text-center `}>My upskills</p> 
                </div>
            </div>
         </div>
        
       </div>

       <div className=' relative '>
       <div className='pt-20 w-[80%] mx-auto relative flex lg:flex-row flex-col items-center justify-center gap-10 '>
            <div className='lg:w-1/2 w-full'>
                <p className='lg:w-[85%] w-[90%] pl-12 text-[#010101] text-xs font-medium '>A DNA assessment reveals comprehensive insights about an individual, including ancestry, health predispositions, traits, and potential genetic risks, empowering users with personalized information for health and lifestyle optimization</p>
                <p className=' font-montserratalt font-bold md:text-8xl text-6xl -rotate-90 absolute md:-left-56 -left-32 top-28 md:top-44 ' style={{ backgroundClip: "text", WebkitBackgroundClip: "text", color: "transparent", backgroundImage: "linear-gradient( to left, #0E2DA7, #51CBFF )" }} >my DnA</p>
                <div className='mt-5'>
                    <img src="../assets/CXO/dashboard/image2.svg" className='w-10/12' />
                </div>
            </div>
            <div className='lg:w-1/2 w-full'>
            <img src="../assets/CXO/dashboard/image1.svg" className='' />
            <div  className="flex justify-center items-center">
            <div className="flex justify-center items-center px-7 bg-[#51CBFF] rounded-2xl gap-5 mt-10 py-2 ">
                    <p className="font-semibold text-[#23515f] font-archivo ">Explore Now</p>
                    <div className=" rotate-45 w-10 h-10 rounded-full bg-white/25 flex justify-center items-center " ><i class="fa-solid fa-arrow-up"></i></div>
                  </div>
                  </div>
            </div>
         </div>
         <div className=' absolute inset-0 top-0 flex justify-center items-center bg-white/85 backdrop-blur-md  '>
             <div className=' w-[95%] mx-auto max-w-xl rounded-3xl bg-white shadow-lg shadow-black/25 p-10 '>
                <p className=' text-center text-2xl font-medium font-goldman '>Coming Soon</p>
                <div className='mt-5 w-[70%] mx-auto'>
                    <div className=' bg-[#F0F1F5] w-28 mx-auto h-2 rounded-full '></div>
                    <div className=' bg-[#F0F1F5] w-full h-2 rounded-full mt-3'></div>
                    <div className=' bg-[#F0F1F5] w-full h-2 rounded-full mt-3'></div>
                    <div className='flex items-center gap-5 mt-10'>
                    <div className=' bg-[#F0F1F5] w-1/2 h-2 rounded-full '></div>
                    <div className=' bg-black w-1/2 h-2 rounded-full '></div>

                    </div>

                </div>
             </div>
         </div>
         </div>
    </div>
  )
}

export default Section2