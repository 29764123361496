import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Section2 = () => {

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      };


  return (
    <div className="h-full w-full pb-10">
        <div className=" -translate-y-5 ">
            <img src="../assets/CXO/Landing/divider.svg"/>
        </div>
        <div className=" w-[80%] mx-auto ">
        <p className="text-[#222222] text-xl font-semibold md:w-[70%] ">Digital thinkers.<br/>
        Small studios build awesome <br/> design.</p>
        <div className="flex lg:flex-row flex-col gap-10">
             <div className=" lg:w-[60%] w-full ">
                 
                 <div className="mt-5">
                 <Slider {...settings}>
                    <div>
                      <img src="../assets/CXO/Landing/sample.svg" />
                    </div>
                    <div>
                      <img src="../assets/CXO/Landing/sample.svg" />
                    </div>
                    <div>
                      <img src="../assets/CXO/Landing/sample.svg" />
                    </div>
                 </Slider>
                 </div>
             </div>
             <div className="lg:w-[40%] w-full mt-6 ">
                  <p className="text-sm text-[#444444] font-light text-left ">Despite being a small team, we believe that our size gives us an advantage, allowing us to be nimble, adaptable, and able to work closely with our clients to deliver truly awesome designs.Despite being a small team, we believe that our size gives us an advantage, allowing us to be nimble, adaptable, and able to work closely with our clients to deliver truly awesome designs.</p>
                  <div className="flex justify-start" >
                  <div className="flex justify-center items-center px-10 bg-[#3AB6FF] rounded-xl gap-5 mt-10 py-3 ">
                    <p className="font-medium">Explore Now</p>
                    <div className=" rotate-45 w-10 h-10 rounded-full bg-white flex justify-center items-center " ><i class="fa-solid fa-arrow-up"></i></div>
                  </div>
                  </div>
                  
             </div>
        </div>
        </div>
    </div>
  )
}

export default Section2