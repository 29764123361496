import React, { useState } from "react";
import axios from "axios";
import { startRegistration, startAuthentication } from "@simplewebauthn/browser";

const API_BASE_URL = "http://localhost:5000"; // Update this if your backend is running on a different port

const WebAuthnAuth = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const register = async () => {
    try {
      setMessage("Generating registration options...");
  
      // 1️⃣ Request Registration Options
      const { data } = await axios.post(`${API_BASE_URL}/register/options`, { email });
  
      if (!data || !data.options || !data.options.challenge) {
        throw new Error("Invalid registration options received from backend.");
      }
  
      // 2️⃣ Start WebAuthn Registration
      const attestationResponse = await startRegistration(data.options);
  
      // 3️⃣ Send Registration Response to Backend
      await axios.post(`${API_BASE_URL}/register/verify`, {
        userId: data.userId,
        response: attestationResponse,
        email,
      });
  
      setMessage("✅ Registration successful!");
    } catch (error) {
      console.error("❌ Registration Error:", error);
      setMessage(`Error: ${error.message || "Failed to register"}`);
    }
  };
  

  // 🔹 Handle Authentication (Login)
  const login = async () => {
    try {
      setMessage("Generating authentication options...");

      // 1️⃣ Get Authentication Options from Backend
      const { data } = await axios.post(`${API_BASE_URL}/login/options`, { email });

      if (!data || !data.challenge) {
        throw new Error("Invalid authentication options received");
      }

      // 2️⃣ Start WebAuthn Authentication
      const assertionResponse = await startAuthentication(data);

      // 3️⃣ Send Authentication Response to Backend for Verification
      await axios.post(`${API_BASE_URL}/login/verify`, {
        userId: data.userId,
        response: assertionResponse,
      });

      setMessage("✅ Login successful! 🎉");
    } catch (error) {
      console.error("❌ Login Error:", error);
      setMessage(`Error: ${error.message || "Failed to authenticate"}`);
    }
  };

  return (
    <div style={styles.container}>
      <h2>🔐 WebAuthn (Passkey) Authentication</h2>
      <input
        type="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        style={styles.input}
      />
      <button onClick={register} style={styles.button}>
        Register (Passkey)
      </button>
      <button onClick={login} style={styles.button}>
        Login (Passkey)
      </button>
      {message && <p style={styles.message}>{message}</p>}
    </div>
  );
};

// 🔹 Simple Styling
const styles = {
  container: { textAlign: "center", marginTop: "50px" },
  input: { padding: "10px", width: "250px", marginBottom: "10px" },
  button: { padding: "10px", margin: "5px", cursor: "pointer" },
  message: { marginTop: "20px", fontWeight: "bold" },
};

export default WebAuthnAuth;
