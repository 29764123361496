import React from 'react'

const Section3 = () => {

    const tableEntries = [
        {
          username: "@sr1809",
          rank: "4",
          score: "400",
        },
        {
            username: "@ayush123",
            rank: "5",
            score: "367",
          },
          {
            username: "@ruchi4567",
            rank: "6",
            score: "340",
          },
          {
            username: "@frenny56789",
            rank: "7",
            score: "320",
          },
          {
            username: "@vijay678",
            rank: "8",
            score: "318",
          },
      ]


  return (
    <div className='w-full h-full bg-white font-poppins py-20'>
        <h1 className='text-center text-3xl font-semibold'>See where you are!</h1>
        <div className='lg:w-[70%] w-[90%] mx-auto bg-[#EDECEC] py-10 mt-10 rounded-2xl relative' >
           
        <div className=' absolute inset-0 md:top-0 flex justify-center items-center bg-white/85 backdrop-blur-md  '>
             <div className=' w-[95%] mx-auto max-w-xl rounded-3xl bg-white shadow-lg shadow-black/25 p-10 '>
                <p className=' text-center text-2xl font-medium font-goldman '>Coming Soon</p>
                <div className='mt-5 w-[70%] mx-auto'>
                    <div className=' bg-[#F0F1F5] w-28 mx-auto h-2 rounded-full '></div>
                    <div className=' bg-[#F0F1F5] w-full h-2 rounded-full mt-3'></div>
                    <div className=' bg-[#F0F1F5] w-full h-2 rounded-full mt-3'></div>
                    <div className='flex items-center gap-5 mt-10'>
                    <div className=' bg-[#F0F1F5] w-1/2 h-2 rounded-full '></div>
                    <div className=' bg-black w-1/2 h-2 rounded-full '></div>

                    </div>

                </div>
             </div>
         </div>
            <div className='md:px-20 px-5 font-syne '>
                <div className='flex md:flex-row flex-col items-center justify-between gap-5 '>
                    <div className='md:w-1/3 w-full bg-[#F3F3F3] rounded-xl shadow-md shadow-black/25 flex items-center md:justify-start justify-center pt-2 px-5 gap-5 '>
                         <img src='../assets/CXO/Landing/SilverMedal.svg' className='w-10' />
                         <p>ghr678</p>
                    </div>
                    <div className='md:w-1/3 w-full bg-[#F3F3F3] rounded-xl shadow-md shadow-black/25 flex items-center md:justify-start justify-center pt-3 pb-2 px-5 gap-5 '>
                         <img src='../assets/CXO/Landing/GoldMedal.svg' className='w-10' />
                         <p>sneha1809</p>
                    </div>
                    <div className='md:w-1/3 w-full bg-[#F3F3F3] rounded-xl shadow-md shadow-black/25 flex items-center md:justify-start justify-center pt-2 px-5 gap-5'>
                         <img src='../assets/CXO/Landing/BronzeMedal.svg' className='w-10' />
                         <p>br7609</p>
                    </div>
                </div>

                <div className="pt-5 rounded-xl overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full text-center">
              <thead>
                <tr className="text-sm md:text-base">
                  <th className="py-5 px-5 md:px-0 whitespace-nowrap">
                    Username
                  </th>
                  <th className="py-5 px-5 md:px-0 whitespace-nowrap">
                    Rank
                  </th>
                  <th className="py-5 px-5 md:px-0 whitespace-nowrap">
                    Score
                  </th>
                </tr>
              </thead>
              <tbody className="bg-transparent">
                {tableEntries.map((entity, index) => (
                  <tr
                    key={index}
                    className="h-12 text-sm md:text-base cursor-pointer rounded-full "
                  >
                    <td className="px-5 lg:px-0 whitespace-nowrap bg-[#4242421F] border-y-8 border-[#EDECEC] rounded-l-full ">
                        {entity.username}
                    </td>
                    <td className="px-5 lg:px-0 whitespace-nowrap bg-[#4242421F] border-y-8 border-[#EDECEC]">
                      {entity.rank}
                    </td>
                    <td className="px-5 lg:px-0 whitespace-nowrap bg-[#4242421F] border-y-8 border-[#EDECEC] rounded-r-full">
                        {entity.score}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
            </div>
        </div>
    </div>
  )
}

export default Section3