import React from 'react'

const Section5 = () => {
  return (
    <div className=' w-full h-full bg-[#51CBFF] font-poppins '>
    <div className=' w-full h-full bg-[#222222] skew-y-2 relative '>
        <div className=' -skew-y-2 ' >
        <div className=' pt-14 flex justify-center items-center gap-5'>
            <p className='text-3xl text-white'>Dedicated</p>
            <div className=' bg-[#51CBFF] -skew-x-12 rounded-lg py-1 px-2 '><p className='text-3xl skew-x-12'>D5 pass</p></div>
        </div>
        <div className='mt-7 md:w-[50%] w-[90%] mx-auto '>
            <p className='text-[#AAAAAA] text-center font-light text-sm'>Katalyst Studio follows a collaborative and iterative approach to design, with a focus on understanding and meeting the unique needs of each client.</p>
        </div>
        <div className='flex mt-10 relative'>
            <img src='../assets/CXO/Landing/image1.svg' className=' md:h-[500px] h-[200px] -translate-y-10 z-30 ' />
            <div className=' absolute left-[30%] z-0 ' >
            <div className='relative'>
            <img src='../assets/CXO/Landing/image2.svg' className=' h-[350px] ' />
            <img src='../assets/Mascots/avatar.svg' className='h-48 absolute md:top-20 top-0 left-0 md:left-20 ' />
            </div>
            </div>
        </div>
        
        </div>
    </div>
    </div>
  )
}

export default Section5