// import { faStar } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import React from 'react';

// const Section1 = () => {
//   return (
//     <div className='w-[90%] mx-auto text-black mt-24 h-full font-poppins'>
        
//         <div className=' relative ' >
//         <p className='md:text-8xl text-4xl font-bold text-center tracking-wide '>Nicolas Cage</p>
//         <div className=' absolute top-0  left-0 right-0 '>
//             <img src='../assets/Mascots/avatar.svg' className=' scale-75 md:scale-150 mx-auto '/>
//         </div>
//         </div>
//         <div className=' w-full flex lg:flex-row flex-col justify-between md:mt-64 mt-52 lg:mt-0'>
//         <div className='lg:w-1/3 w-[90%] lg:mx-0 mx-auto bg-gradient-to-bl from-[#51CBFF] to-[#000000] rounded-xl p-2 mt-10'>
//             <div className=' bg-gradient-to-bl from-[#FFFFFF] from-60% to-[#999999] to-98% rounded-xl h-full py-5 '>
//                <p className='text-2xl font-semibold text-center '>Skill progress</p>
//                <div className='w-[85%] mx-auto my-5'>
//                 <div className='w-full'>
//                     <p className=' text-sm '>React</p>
//                     <div className='mt-1 bg-gradient-to-b from-[#F4F9FF] to-[#BDC2C8] rounded-full w-full '>
//                         <div className=' bg-gradient-to-b from-[#988AFF] to-[#5945ED] h-full w-32 rounded-full p-2' ></div>
//                     </div>
//                 </div>
//                 <div className='w-full mt-5'>
//                     <p className=' text-sm '>Tailwind CSS</p>
//                     <div className='mt-1 bg-gradient-to-b from-[#F4F9FF] to-[#BDC2C8] rounded-full w-full '>
//                         <div className=' bg-gradient-to-b from-[#988AFF] to-[#5945ED] h-full w-20 rounded-full p-2' ></div>
//                     </div>
//                 </div>
//                 <div className='w-full mt-5'>
//                     <p className=' text-sm '>HTML</p>
//                     <div className='mt-1 bg-gradient-to-b from-[#F4F9FF] to-[#BDC2C8] rounded-full w-full '>
//                         <div className=' bg-gradient-to-b from-[#988AFF] to-[#5945ED] h-full w-40 rounded-full p-2' ></div>
//                     </div>
//                 </div>
//                 <div className='w-full mt-5'>
//                     <p className=' text-sm '>NODE JS</p>
//                     <div className='mt-1 bg-gradient-to-b from-[#F4F9FF] to-[#BDC2C8] rounded-full w-full '>
//                         <div className=' bg-gradient-to-b from-[#988AFF] to-[#5945ED] h-full w-12 rounded-full p-2' ></div>
//                     </div>
//                 </div>

//                </div>
//             </div>
//         </div>
//         <div className='lg:w-1/3 w-[90%] lg:mx-0 mx-auto mt-10 '>
//               <p className='text-sm  leading-6'>We’re the<span className='text-[#22DCFD]'> First Academy of Blockchain Consultancy</span> and we’re not just about learning—we’re about giving you a complete package: skills, employability, innovation, and tech entrepreneurship <strong>(yes, turning your tech ideas into reality)</strong>.</p>
//               <div className='mt-14 bg-[#D9D9D9] rounded-xl p-2'>
//                 <div className='rounded-xl bg-white px-4 py-7 flex items-start justify-center gap-5  '>
//                     <div className=' bg-[#F29D38] rounded-full w-10 h-10 flex justify-center items-center '>
//                         <FontAwesomeIcon icon={faStar} size='lg'className='text-white' />
//                     </div>
//                     <div className='text-[#010101] mt-1'>
//                         <p className='text-xl font-bold'>Chief Marketing Officer</p>
//                         <p className='font-semibold text-sm mt-1'>FABC LLC</p>
//                     </div>
//                 </div>
//               </div>
//         </div>
//         </div>
//     </div>
//   )
// }

// export default Section1




import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faEdit } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "../../../../../Slice/profileSlice";
import EditModal from "./EditModal";


const UserProfile = () => {
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.userProfile);
      const  userEmail = useSelector((state) => state.auth.user);
      console.log("user",user)
      console.log("userEmail",userEmail)
  console.log("user", user)
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchUser(userEmail?.email));
  }, [dispatch,userEmail]);

  return (

    <div className="w-[90%] mx-auto text-black mt-24 h-screen font-poppins">
      <div className="relative">
        <p className="md:text-8xl text-4xl font-bold text-center tracking-wide">
          {userEmail?.user_name}
        </p>
        <div className="absolute top-0 left-0 right-0">
          <img src={user?.avatar || "../assets/Mascots/avatar.svg"} className="scale-75 md:scale-150 mx-auto" />
        </div>
      </div>
      {user  ? (
<>
      <div className="w-full flex lg:flex-row flex-col justify-between md:mt-64 mt-52 lg:mt-0">
        {/* Skill Progress Section */}
        <div className="lg:w-1/3 z-10 w-[90%] lg:mx-0 mx-auto bg-gradient-to-bl from-[#51CBFF] to-[#000000] rounded-xl p-2 mt-10">
          <div className="bg-gradient-to-bl from-[#FFFFFF] from-60% to-[#999999] to-98% rounded-xl h-full py-5">
            <div className="flex justify-between items-center px-5">
              <p className="text-2xl font-semibold">Skill Progress</p>
              <FontAwesomeIcon
                icon={faEdit}
                className="text-gray-600  z-10 cursor-pointer"
                onClick={() => setIsModalOpen(!isModalOpen)}
              />
            </div>
            <div className="w-[85%] h-56 overflow-hidden overflow-y-auto  mx-auto my-5">
                {user?.skills && 
                    Object.entries(JSON.parse(user.skills)).map(([skill, percentage]) => (
                    <div key={skill} className="w-full mt-5">
                        <p className="text-sm">{skill}</p>
                        <div className="mt-1 bg-gray-300 rounded-full w-full">
                        <div
                            className="bg-blue-500 h-full rounded-full p-2"
                            style={{ width: `${percentage}%` }}
                        ></div>
                        </div>
                    </div>
                    ))}
                </div>

          </div>
        </div>

        {/* User Role Section */}
        <div className="lg:w-1/3 w-[90%] lg:mx-0 mx-auto mt-10">
          <p className="text-sm leading-6">{user?.description}</p>
          <div className="mt-14 bg-[#D9D9D9] rounded-xl p-2">
            <div className="rounded-xl bg-white px-4 py-7 flex items-start justify-center gap-5">
              <div className="bg-[#F29D38] rounded-full w-10 h-10 flex justify-center items-center">
                <FontAwesomeIcon icon={faStar} size="lg" className="text-white" />
              </div>
              <div className="text-[#010101] mt-1">
                <p className="text-xl font-bold">{user?.role}</p>
                <p className="font-semibold text-sm mt-1">{user?.company}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
        ):(
            <p>Loading......</p>
        )}
{isModalOpen  &&  <EditModal user={user}  userEmail={userEmail}     disableCancel={user?.message === "User not found"}  onClose={() => setIsModalOpen(!isModalOpen)} />}
</div>
  );
};

export default UserProfile;
