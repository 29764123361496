import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SERVER_URL } from "../config";
import toast from "react-hot-toast";


export const fetchOrganizations = createAsyncThunk(
  "organizations/fetchOrganizations",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${SERVER_URL}/api/admin/cxo/get-organizations`);
      console.log("Fetched Organizations:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching organizations:", error);
      return rejectWithValue(error.response?.data || "Server error");
    }
  }
);




export const createOrganization = createAsyncThunk(
  "organizations/createOrganization",
  async (orgData, { rejectWithValue }) => {
    const toastId = toast.loading("Creating organization..."); // Show loading toast

    try {
      const response = await axios.post(
        `${SERVER_URL}/api/admin/cxo/create-organization`,
        orgData
      );

      console.log("Created Organization:", response.data);

      toast.success("Organization created successfully", { id: toastId }); // Update toast to success
      return response.data;
    } catch (error) {
      console.error("Error creating organization:", error);
      
      toast.error(error.response?.data?.message || "Failed to create organization", { id: toastId }); // Update toast to error
      return rejectWithValue(error.response?.data || "Server error");
    }
  }
);
const adminCxoOrganizationSlice = createSlice({
  name: "adminCxoOrganization",
  initialState: {
    organizations: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrganizations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrganizations.fulfilled, (state, action) => {
        state.loading = false;
        state.organizations = action.payload;
      })
      .addCase(fetchOrganizations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createOrganization.fulfilled, (state, action) => {
        state.organizations.push(action.payload);
      });
  },
});

export default adminCxoOrganizationSlice.reducer;
