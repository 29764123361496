import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import { SERVER_URL } from "../config";


export const fetchConsultations = createAsyncThunk(
  "cxoConsultation/fetchConsultations",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${SERVER_URL}/api/admin/cxo/get-consultation`);
      console.log("response",response)
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Error fetching data";
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const createConsultation = createAsyncThunk(
  "cxoConsultation/createConsultation",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${SERVER_URL}/api/admin/cxo/create-consultation`, formData);
      toast.success("Consultation created successfully!");
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Server error";
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

const cxoConsultationSlice = createSlice({
    name: "cxoConsultation",
    initialState: { consultations: [], loading: false, error: null },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchConsultations.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchConsultations.fulfilled, (state, action) => {
          state.loading = false;
          state.consultations = action.payload.consultations || []; // Ensure only consultations are stored
        })
        .addCase(fetchConsultations.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
        .addCase(createConsultation.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(createConsultation.fulfilled, (state, action) => {
          state.loading = false;
          state.consultations.push(action.payload); // Append new consultation to state
        })
        .addCase(createConsultation.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
    },
  });
  
export default cxoConsultationSlice.reducer;